.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $mobile-breakpoint) {
        &.nav--fixed {
            // position: fixed;
        }
    }

    .nav__section {
        font-family: $paragraph-font-family;
        font-weight: $paragraph-font-weight;
        margin: 15px 0;
        // display: flex;
        // display: inline-block;
        font-size: inherit;
        color: $color-black;

        @media (min-width: $mobile-breakpoint) {
            // display: block;
        }

        &.nav__section--left {}

        &.nav__section--right {
            // float: right;

            @media (min-width: $mobile-breakpoint) {
                float: none;
            }
        }

        a {
            color: $color-blue;
        }
    }

    .nav__header {
        @media (min-width: $mobile-breakpoint) {
            // font-size: 22px;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        span {
            display: none;

            @media (min-width: $mobile-breakpoint) {
                display: inline;
            }
        }
    }

    .nav__list {
        list-style-type: none;
        padding: 0;

        li {
            display: inline-block;
            margin-left: 3px;

            @media (min-width: $mobile-breakpoint) {
                // display: block;
                margin-top: 8px;
                margin-bottom: 8px;
                font-size: inherit;
            }

            a {
                margin-left: 5px;
                text-decoration: none;

                @media (min-width: $mobile-breakpoint) {
                    margin-left: 0;
                }

                &.active,
                &:hover {
                    text-decoration: none;
                    border-bottom: 2px solid #3498db;
                }
            }
        }
    }
}
