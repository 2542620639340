* {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}


html {
    font-size: $mobile-base-font-size;
    font-family: $paragraph-font-family;
    color: $color-black;

    @media (min-width: $mobile-breakpoint) {
        font-size: $base-font-size;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;

    &.weight--700 {
        font-weight: 600;
    }

    &.weight--600 {
        font-weight: 600;
    }

    &.weight--500 {
        font-weight: 500;
    }

    &.weight--400 {
        font-weight: 400;
    }

    &.weight--300 {
        font-weight: 300;
    }
}

h1 {
    font-weight: 550;
}

h2 {
    font-weight: 450;
}

p, li {
    line-height: $mobile-paragraph-line-height;

    @media (min-width: $mobile-breakpoint) {
        line-height: $paragraph-line-height;
    }
}

a {
    color: $color-blue;

    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

hr {
    border: 0;
    border-top: 2px solid $color-gray;
}

.list-inline {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        display: inline;
        margin: 0;
        padding: 0;
    }
}
