.hero__wrapper {
    display: table-cell;
    vertical-align: middle;
}

.hero {
    // background-color: $color-blue;
    // border-radius: 4px;
    // max-width: 750px;
    padding: 20px;
    
    @media (min-width: $mobile-breakpoint) {
        padding: 50px;
    }
    
    .hero__header {
        margin: 0;
        // color: $color-white;
    }
    
    .hero__content {
        // color: $color-white;
        
        a {
            // color: inherit;
            text-decoration: underline;
        }
    }
}
