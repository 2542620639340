footer {
    margin-top: 15px;
    margin-bottom: 15px;
    
    p {
        margin: 0;
        text-align: center;
        color: $color-gray;
    }
}
