@media (min-width: $mobile-breakpoint) {
    .page {
        // display: flex;

        &.page--home {
            // height: 100vh;
        }
    }

    .page__nav {
        // width: 20%;
    }

    .page__main {
        // width: 80%;
        // margin-top: 15px;
    }
}
