.post {
    margin-bottom: 25px;
    
    @media (min-width: $mobile-breakpoint) {
        margin-bottom: 35px;
    }
    
    .post__time {
        margin: 0;
        margin-bottom: 14px;
        font-size: 14px;
        color: $color-gray;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    
    &.post--list-item {
        .post__time {
            margin: 0;
        }
    }
    
    &.post--home {
        margin-top: 25px;
    }
    
    .post__header {
        margin: 0;
        
        a {
            text-decoration: none;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    .post__content {
        div, video, figure, img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }
    }
}
