// Colors
// ---
$color-white: #fff;
$color-black: #333;
$color-gray:  #ccc;
$color-blue:  #3498db;


// Fonts
// ---
$heading-font-weight: 500;
$paragraph-font-weight: 400;
$paragraph-line-height: 1.4;
$mobile-paragraph-line-height: 1.4;

$base-font-size: 16px;
$mobile-base-font-size: 15px;

$heading-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;//'Lato', sans-serif;
$paragraph-font-family: $heading-font-family;//'Noto Sans', sans-serif;

// Breakpoints
// ---
$mobile-breakpoint: 700px;
