.search {
    .search__input {
        border: 0;
        outline: 0;
        margin: 0;
        margin-bottom: 25px;
        padding: 0;
        font-family: $paragraph-font-family;
        color: $color-black;
        
        @media (min-width: $mobile-breakpoint) {
            margin-bottom: 35px;
        }
        
        &::-webkit-input-placeholder {
            color: $color-gray;
        }
        
        &::-moz-placeholder {
            color: $color-gray;
            opacity: 1;
        }
        
        &:-ms-input-placeholder {
            color: $color-gray;
        }
    }
}

.search__no-results {
    display: none;
}
