.main--home {
    display: table;
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.main__wrapper {
    display: table-cell;
    vertical-align: middle;
}
